import React, { useState } from "react";

const Aboutcards = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div id={data?.id}>
      <div
        className={`flex px-[20px] sm:px-[24px] md:px-[64px] py-[64px] md:py-[112px] gap-[16px] md:gap-[80px] flex-col ${
          data?.bg
        } ${data?.imgleft === "true" ? "md:flex-row-reverse" : "md:flex-row"}`}
      >
        <div className="flex flex-col w-full md:w-1/2 justify-center">
          <p className="text-[#051367] text-[16px] md:text-[20px] font-semibold">
            {data?.name}
          </p>
          <h1 className="font-extrabold text-[36px] lg:text-[48px] leading-[120%] lg:leading-[57px] md:mb-[24px] mb-[16px] mt-[16px] text-[#2E3232]">
            {data?.headline}
          </h1>
          <div className="flex flex-col ">
            <p className="text-[16px] lg:text-[18px] text-[#2E3232] leading-[160%] lg:leading-[28px] mb-[20px]">
              {data?.content1}
            </p>
            {data?.content2.length !== 0 && (
              <p className="text-[16px] lg:text-[18px] text-[#2E3232] leading-[160%] lg:leading-[28px] mb-[20px]">
                {data?.content2}
              </p>
            )}
          </div>
          {expanded && (
            <div className="flex flex-col">
              <p className="text-[16px] lg:text-[18px] text-[#2E3232] leading-[160%] lg:leading-[28px] mb-[20px]">
                {data?.extraText1}
              </p>
              {data?.extraText2 && (
                <p className="text-[16px] lg:text-[18px] text-[#2E3232] leading-[160%] lg:leading-[28px] mb-[20px]">
                  {data?.extraText2}
                </p>
              )}
            </div>
          )}
          <div className="flex mb-[24px]">
            <p
              className={`${data?.btnbg} rounded-[4px] text-[white] px-[10px] py-[6px] flex hover:cursor-pointer text-[16px] font-semibold`}
              onClick={toggleExpand}
            >
              {expanded ? "Read Less" : data?.button}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 justify-center ">
          <img src={data?.image} alt="" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default Aboutcards;