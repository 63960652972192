import React from 'react'
import Aboutcards from '../component/Aboutcards'

const aboutCardData = [
{
   name:"About us",
   headline:"Shaping the future of your business through technology",
   content1:"At the forefront of technological innovation, we are dedicated to transforming businesses with cutting-edge solutions. Our mission is to deliver innovative strategies that enhance efficiency and drive growth. We pride ourselves on our unwavering commitment to excellence, integrity, and customer satisfaction.",
   content2:"By staying ahead of industry trends, we ensure our customers are equipped with the most advanced, high-quality technology available. Join us on our journey to shape the future of your business through technology.",
   button:"",
   image:"./assets/images/about.png",
   bg:"",
   imgleft:"",
   id:"about"
},
{
    name:"Enterprise Application Development and Modernization",
    headline:"Modernizing your business to gain competitive edge",
    content1:"In today's fast-paced business environment, staying competitive requires robust, scalable, and modern enterprise applications. Our enterprise application development and modernization services are designed to propel your business into the future.",
    content2:"",
    button:"Read More",
    image:"./assets/images/enterprise.png",
    bg:"bg-[#E4F1FE]",
    btnbg:"bg-[#051367]",
    imgleft:"true",
    extraText1:"We specialize in creating custom applications that streamline your operations, enhance productivity, and provide valuable insights. Whether you are looking to build new applications from the ground up or modernize your legacy systems, we work closely with you to understand your business processes and deliver solutions that drive real value",
    extraText2:"From cloud-native applications to microservices architectures, we leverage the latest technologies and best practices to ensure your enterprise applications are not just meeting today's needs but are also ready for tomorrow's challenges. With our expertise, you can transform your business processes, improve user experiences, and gain a competitive edge in your industry.",
    id:"enterprise"
   },
 {
    name:"Data and Intelligence",
    headline:"Unlocking the full potential of your data assets to drive innovation",
    content1:"Our data and intelligence services unlock the full potential of your data assets while optimizing costs. We offer end-to-end solutions from data collection to advanced analytics and visualization, turning raw data into actionable insights.",
    content2:"",
    button:"Read More",
    image:"./assets/images/unlocking.png",
    bg:"",
    btnbg:"bg-[#051367]",
    imgleft:"",
    extraText1:"Our strategic approach to data migration and management is a cornerstone of our offering. We implement cost-effective strategies by selectively migrating high-value data to active systems while providing efficient archival and retrieval solutions for less frequently accessed information. This approach ensures critical data availability while significantly reducing migration costs. Our archival solutions are designed for long-term preservation, compliance, and easy retrieval. Whether you're upgrading systems, moving to the cloud, or consolidating data, our strategies minimize disruption, maintain data integrity, and optimize IT spend. With our solutions, you can drive data-driven innovation across your organization while managing your data in the most cost-effective manner possible.",
    extraText2: "",
    id:"data"
 },
]

const AboutUs = () => {
  return (
    <div>
        {
           aboutCardData.map((data)=>(
            (
                <Aboutcards data={data}/>
            )
           )) 
        }
    </div>
  )
}

export default AboutUs