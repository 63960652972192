import React from 'react'
import Aboutcards from '../component/Aboutcards'

const aboutCardData = [
{
   name:"Cybersecurity Solutions",
   headline:"Protecting your digital frontier",
   content1:"In an interconnected digital world, robust cybersecurity is not just an option – it's a necessity. Our cybersecurity solutions are designed to protect your most valuable assets: your data, your systems, and your reputation.",
   content2:"",
   button:"Read More",
   image:"./assets/images/protect.png",
   bg:"",
   btnbg:"bg-[#051367]",
   imgleft:"true",
   extraText1:"We offer a comprehensive suite of services that cover every aspect of cybersecurity, from threat detection and prevention to incident response and recovery. Our team of security experts stays ahead of the latest threats and vulnerabilities, employing advanced technologies like AI-powered threat intelligence and behavioral analytics to safeguard your digital infrastructure.",
   extraText2:"We provide services such as penetration testing, vulnerability assessments, and security audits to identify and address potential weaknesses before they can be exploited. Additionally, we help you develop and implement robust security policies and provide training to create a culture of security awareness within your organization. With our cybersecurity solutions, you can confidently embrace digital transformation, knowing that your assets are protected against even the most sophisticated cyber threats.",
   id:"cybersecurity"
  },
{
    name:"IT Managed Services",
    headline:"Simplify IT complexity and amplify your business efficiency",
    content1:"In a tech-centric business world, a robust IT infrastructure is vital, yet challenging to maintain. Our IT managed services take the burden off your shoulders, allowing you to focus on your core business while we ensure your IT systems are running smoothly and securely.",
    content2:"",
    button:"Read More",
    image:"./assets/images/simplify.png",
    bg:"bg-[#E4F1FE]",
    btnbg:"bg-[#051367]",
    imgleft:"",
    extraText1:"We offer a comprehensive range of services, including 24/7 monitoring and management of your IT infrastructure, help desk support, network administration, and system updates and maintenance. With our proactive approach, we identify and resolve potential issues before they can impact your operations, minimizing downtime and maximizing productivity.",
    extraText2:"We also provide strategic IT consulting to help align your technology investments with your business goals, ensuring you're always leveraging the most appropriate and cost-effective solutions. With our IT managed services, you can enjoy peace of mind knowing that your IT needs are being handled by experienced professionals, allowing you to allocate your resources more efficiently and drive your business forward.",
    id:"it"
   },
 {
    name:"SparkForge – Our Innovation Hub",
    headline:"Transforming enterprise vision into digital reality",
    content1:"SparkForge is our innovation hub where creativity meets technology. This center of excellence is dedicated to developing cutting-edge prototypes and delivering actionable insights on emerging technologies.",
    content2:"",
    button:"Read More",
    image:"./assets/images/mobile.png",
    bg:"",
    btnbg:"bg-[#051367]",
    imgleft:"true",
    extraText1:"Here, we spark new ideas and forge innovative solutions, bridging the gap between theoretical potential and practical application. Our experts craft proof-of-concepts that showcase the real-world impact of breakthrough technologies. Through this hands-on approach, we generate valuable insights into implementation strategies and best practices. SparkForge can produce comprehensive resources including interactive demos and trend analyses, empowering businesses to make informed decisions about adopting new technologies. Whether you're seeking to understand the implications of emerging technologies or looking for inspiration to drive innovation, SparkForge can be your partner in navigating the future of technology.",
    extraText2:"",
    id:"sparkForge"
 },
]

const AboutUsLast = () => {
  return (
    <div>
        {
           aboutCardData.map((data)=>(
            (
                <Aboutcards data={data}/>
            )
           )) 
        }
    </div>
  )
}

export default AboutUsLast