import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="grid place-items-center min-h-screen">
      <div className="max-w-3xl mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h1>
        <h2 className="text-xl font-bold mt-6 mb-2">Introduction</h2>
        <p className="mb-4">
          Welcome to{" "}
          <Link
            target="_blank"
            to="https://www.universaltech.com"
            className="text-blue-600 hover:underline"
          >
            https://www.universaltech.com
          </Link>{" "}
          (the "Website"). Your privacy is important to us. This Privacy Policy
          explains our practices regarding the collection, use, and disclosure
          of information when you use our Website.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-2">
          Information Collection and Use
        </h2>
        <p className="mb-4">
          We want to assure you that we do not collect any personal data from
          users who visit our Website. As such, we do not gather, store, or
          process any personal information from our visitors.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">Cookies</h2>
        <p className="mb-4">
          Our Website does not use cookies to track or store any personal
          information.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">Third-Party Links</h2>
        <p className="mb-4">
          Our Website may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party's site.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
